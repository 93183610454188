.card {
  border-radius: 10px;
  transition: 0.8s;
  overflow: hidden;
  background: black;
  box-shadow: 0 70px 63px -60px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
}
.profile-pic {
    height: 100%;
    width: 100%;
    animation-duration: 2s;
    transition: 1s;
    animation-timing-function: ease-in-out;
    backface-visibility: hidden;
    object-fit: cover;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    z-index: 5;
}
.profile-pic:hover {
    scale: 1.1;
    clip-path: polygon(30% 0, 100% 0, 100% 71%, 20% 75%);
}
.profile-pic::after {
    scale: 1;
}

.left-text {
    writing-mode: vertical-rl;
    transform: scale(-1, -1);   
}