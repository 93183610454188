.slice-container {
    clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
}
.slice-container-2 {
    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
}
.scrolling-image {
    background: url("../components/products/notesBg.png") repeat-x repeat-y;
    height: 7500px;
    width: 7680px;
  }